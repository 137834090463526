import * as React from 'react';
import './embed-youtube.scss';
import AudioDescription from '../audio-description/audio-description';

const EmbedYoutube = ({ url, externalLink, audioDesc, notGalleryEmbed = true }) => {
  return (
    <>
      {notGalleryEmbed &&
        <div className='row justify-content-center pt-5'>
          <div className='videoWrapper col-12 col-lg-10 col-xl-8'>
            <iframe src={url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {externalLink ? <div className="col-12 small pt-1"><span><a target='_blank' href={externalLink}>To view this work fullscreen click here. (opens in new window)</a></span></div> : ''}
          </div>
          {audioDesc && <AudioDescription audioURL={audioDesc} />}
        </div>
      }
      {!notGalleryEmbed &&
        <>
          <iframe src={url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          {externalLink ? <div className="col-12 small pt-1"><span><a target='_blank' href={externalLink}>To view this work fullscreen click here. (opens in new window)</a></span></div> : ''}
          {audioDesc && <AudioDescription audioURL={audioDesc} />}
        </>
      }
    </>
  )
}

export default EmbedYoutube;