import * as React from 'react';
import './audio-description.scss';

const AudioDescription = ({ audioURL }) => {
  return (
    <>
      <div className='col-12 text-center'>
        <span className='small'>Audio Description</span>
      </div>
      <div className='col-12 text-center audio-description'>
        <audio controls src={audioURL}></audio>
      </div>
    </>
  )
}

export default AudioDescription;