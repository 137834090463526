import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import { graphql } from "gatsby";
import EmbedYoutube from "../components/embed-youtube/embed-youtube";
import EmbedWebpage from "../components/embed-webpage/embed-webpage";
import Gallery from '../components/gallery/gallery';
import AudioDescription from '../components/audio-description/audio-description';

const Artwork = ({ data }) => {
  const fm = data.markdownRemark.frontmatter;
  const navigationData = data.allMarkdownRemark.edges.find(x => x.node.id === data.markdownRemark.id);

  const getEmbed = () => {
    switch (fm.type) {
      case "embed-youtube":
        return <EmbedYoutube url={fm.link} externalLink={fm.external_link} audioDesc={fm.audio_description ? fm.audio_description.publicURL : null} />
      case "embed-webpage":
        return <EmbedWebpage url={fm.link} externalLink={fm.external_link} audioDesc={fm.audio_description ? fm.audio_description.publicURL : null} />
      case "gallery":
        return <Gallery embedMedia={fm.embed_media} />
      default:
        return <div></div>
    }
  }

  const hasNext = () => {
    if (!navigationData) return false;
    return !!navigationData.next;
  }

  const hasPrevious = () => {
    if (!navigationData) return false;
    return !!navigationData.previous;
  }

  return (
    <Layout
      pageTitle={`${fm.artist} - ${fm.title}`} 
      description={fm.title} 
      ogData={{
        image: `${fm.hero_image.childImageSharp.fluid.src}`,
        link: `/artworks/${fm.slug}`,
        desc: `${data.markdownRemark.excerpt}`
      }}>
      <main>
        {/* The header and meta */}
        <div className={'bg-pattern'}>
          <div className={'container pt-3 bg-col'}>
            <div className='row'>
              <div className='col pb-4'>
                <a href="/">
                  <StaticImage src="../images/logo.png" className='logo-sml' alt="Queertech.io Logo" />
                </a>
              </div>
            </div>
            <div className='row'><div className='col'><span className="small">Artist Name</span></div></div>
            <div className='row'><div className='col'><h2 className='header-text-green text-pixel'>{fm.artist}</h2></div></div>
            <div className='row'><div className='col'><span className="small">Title of Work</span></div></div>
            <div className='row'><div className='col'><h2 className='header-text-pink text-pixel'>{fm.title}</h2></div></div>
            {/* <div className='row text-center pt-3 pb-3'>
              <div className='col'><a href={`${fm.website}`}>Artist's Website</a></div>
              <div className='col'>Country: {fm.country}</div>
              <div className='col'>Year: {fm.year}</div>
            </div> */}
          </div>
        </div>

        {/* The embedded work */}
        <div className='container-fixed bg-col-mid'>
          {fm.warning ? <div className='row pt-5'><div className="col-12"><div className='alert alert-custom text-center' role={'alert'}>{fm.warning}</div></div></div> : ''}
          {fm.audioDescription &&  <AudioDescription audioURL={fm.audioDescription.publicURL} />}
          {getEmbed()}
          <div className='row text-center pt-3 pb-3 artwork-navigation'>
            <div className='col'>{hasPrevious() ? <a className='btn btn-outline-primary' href={`/${navigationData.previous.frontmatter.slug}`}>Previous</a> : ''}</div>
            {/* <div className='col'>Random</div> */}
            <div className='col'><a className='btn btn-outline-primary' href="/">List</a></div>
            <div className='col'>{hasNext() ? <a className='btn btn-outline-primary' href={`/${navigationData.next.frontmatter.slug}`}>Next</a> : ''}</div>
          </div>
        </div>

        {/* About the work section */}
        <div className={'bg-pattern'}>
          <div className={'container bg-col pt-3'}>
            <div className='row'>
              <div className="col">
                <h3 className='text-pixel header-text-green'>About This Work</h3>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className="artwork-body col-12 col-md-10 col-lg-8" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            </div>
            {fm.links &&
              <div className='row'>
                <h3 className='text-pixel header-text-pink'>Connect With {fm.artist}</h3>
                <div className='row text-center pb-3 justify-content-center'>
                  {fm.links.map((x, y) => <div className='col-4 col-md-3 col-lg-2' key={y}><a href={x.link}>{x.text}</a></div>)}
                </div>
              </div>
            }
          </div>
        </div>

      </main>
    </Layout>
  )
}

export const query = graphql`
query ($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    excerpt(format: PLAIN, pruneLength: 200)
    frontmatter {
      artist
      country
      link
      slug
      title
      type
      year
      warning
      hero_image {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      audioDescription {
        publicURL
      }
      audio_description {
        publicURL
      }
      links {
        text
        link
      }
      external_link
      embed_media {
        title
        type
        embedURL
        externalURL
        ratioOverride
        audioDescription {
          publicURL
        }
        mediaURL {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
    }
    id
  }
  allMarkdownRemark {
    edges {
      node {
        id
      }
      next {
        id
        frontmatter {
          slug
        }
      }
      previous {
        id
        frontmatter {
          slug
        }
      }
    }
  }
}
`

export default Artwork