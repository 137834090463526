import * as React from 'react';
import './gallery.scss';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import AudioDescription from '../audio-description/audio-description';
import EmbedYoutube from '../embed-youtube/embed-youtube';
import EmbedWebpage from '../embed-webpage/embed-webpage';

const Gallery = ({ embedMedia }) => {
  return (
    // <div className='videoWrapper col-12 col-lg-10 col-xl-8'>
    //   <iframe src={url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    //   {externalLink ? <div class="col-12 small pt-1"><span><a href={externalLink}>To view this work fullscreen click here.</a></span></div> : ''}
    // </div>
    <div className='row justify-content-center pt-5'>
      {embedMedia.map((x, y) => <GalleryItem key={y} data={x} isLast={y == embedMedia.length-1} />)}
    </div>
  )
}

export default Gallery;

const GalleryItem = ({ data, isLast }) => {
  const getMediaObject = () => {
    switch (data.type) {
      case 'gif':
        return <img src={`${data.mediaURL.publicURL}`} />
      case 'image':
        return <GatsbyImage image={data.mediaURL.childImageSharp.gatsbyImageData} alt={data.title} />
      case 'video':
        return <video autoPlay controls className='mx-auto' src={data.mediaURL.publicURL}></video>
      case 'embed-youtube':
        return <EmbedYoutube url={data.embedURL} externalLink={data.externalURL} audioDesc={null} notGalleryEmbed={false} />
      case 'embed-website':
        return <EmbedWebpage url={data.embedURL} externalLink={data.externalURL} audioDesc={null} ratioOverride={data.ratioOverride || null} />
      default:
        return <></>
    }
  }

  const getExtraWrapperClass = () => {
    switch(data.type) {
      case 'embed-youtube':
      case 'embed-website':
        return 'videoWrapper';
      default:
        return '';
    }
  }

  return (
    <>
      <div className={`col-12 col-lg-10 col-xl-8 galleryItem text-center pb-4 pt-4 ${getExtraWrapperClass()}`} >
        {getMediaObject()}
        <div className='pt-1'><i>{data.title}</i></div>
      </div>
      {data.audioDescription && <AudioDescription audioURL={data.audioDescription.publicURL} />}
      {!isLast &&
        <div className='col-12 text-center pt-3'>
          <StaticImage
            src='../../images/slash.png'
            width={32}
            quality={90}
            alt="A small decorative image to visually divide between images"
          />
        </div>
      }
    </>
  )
}
